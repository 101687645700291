/**
 * @generated SignedSource<<5b178a01ac9640d9b92bca561bcc2aa3>>
 * @relayHash 2597f79118a372112d63ef521d453f56
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/477.0.0-2024-08-23T22:21:23.075Z/useAddItemToCart_userCartItemAddMutation

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CartType = "CHECKOUT" | "SAVE_FOR_LATER" | "%future added value";
export type UserCartItemAddOrUpdateInput = {
  clientMutationId?: string | null;
  itemId: string;
  quantity: number;
  skuId: string;
  type: CartType;
};
export type useAddItemToCart_userCartItemAddMutation$variables = {
  input: UserCartItemAddOrUpdateInput;
};
export type useAddItemToCart_userCartItemAddMutation$data = {
  readonly userCartItemAddOrUpdate: {
    readonly clientMutationId: string | null;
  } | null;
};
export type useAddItemToCart_userCartItemAddMutation = {
  response: useAddItemToCart_userCartItemAddMutation$data;
  variables: useAddItemToCart_userCartItemAddMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UserCartItemAddOrUpdatePayload",
    "kind": "LinkedField",
    "name": "userCartItemAddOrUpdate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useAddItemToCart_userCartItemAddMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useAddItemToCart_userCartItemAddMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "ferrum/477.0.0-2024-08-23T22:21:23.075Z/useAddItemToCart_userCartItemAddMutation",
    "metadata": {},
    "name": "useAddItemToCart_userCartItemAddMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "caa2e89bc1be2beaf2a9561badc03a63";

export default node;
